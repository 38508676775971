// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(10, 10, 10);
}

h1 {
    font-family: Arial, Helvetica, sans-serif;
    color: #1cb52f
}

img {
    cursor: pointer;
}

.links img.brighten {
    filter:brightness(8);
}

.links img:hover {
    transform: scale(1.3);
}

.links {
    margin-top: 40px;
    margin-bottom: 20px;
}

.row {
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
}
  
.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.responsive {
    max-width: 100%;
    display: block;
    height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iCAAiC;AACrC;;AAEA;IACI,yCAAyC;IACzC;AACJ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,OAAO;AACX;;AAEA;IACI,eAAe;IACf,cAAc;IACd,YAAY;AAChB","sourcesContent":["body {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-color: rgb(10, 10, 10);\n}\n\nh1 {\n    font-family: Arial, Helvetica, sans-serif;\n    color: #1cb52f\n}\n\nimg {\n    cursor: pointer;\n}\n\n.links img.brighten {\n    filter:brightness(8);\n}\n\n.links img:hover {\n    transform: scale(1.3);\n}\n\n.links {\n    margin-top: 40px;\n    margin-bottom: 20px;\n}\n\n.row {\n    justify-content: space-evenly;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    width: 100%;\n    max-width: 800px;\n}\n  \n.column {\n    display: flex;\n    flex-direction: column;\n    flex-basis: 100%;\n    flex: 1;\n}\n\n.responsive {\n    max-width: 100%;\n    display: block;\n    height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
