import './styles.css';

const styleArray= [
  'color: #fff',
  'padding: 10px 20px',
  'line-height: 35px',
  'width : 70px',
  'height : 70px',
  'border : 5px solid black',
  '-webkit-text-stroke: 1px black',
  'font-size:20px'
];
console.log("%cRead This: https://grugbrain.dev/", styleArray.join(';'));

